import Link from "next/link"

export const JoinUsInfo = () => {
  return (
    <div className="mt-10">
      <span className="text-text-tertiary">Don’t have an account?</span>
      <Link
        href="https://www.processout.com/contact-us"
        target="_blank"
        className="mx-2 font-medium"
      >
        Join us
      </Link>
    </div>
  )
}
