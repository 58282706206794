import { useState } from "react"
import { Controller, useForm } from "react-hook-form"

import { getOrganizationPagePath } from "app/auth/_utils"
import { Sections } from "app/auth/sign-in/_types/types"
import { ChallengeResponseDetails } from "app/auth/sign-in/page"
import clsx from "clsx"
import ErrorBlock from "components/Errors/ErrorBlock"
import { signIn } from "next-auth/react"
import { useRouter, useSearchParams } from "next/navigation"
import Loader from "src/components/Loader/Loader"
import { Button } from "src/orca/components/Button/Button"
import { OTP } from "src/orca/components/OTP/otp"
import { Typography } from "src/orca/components/Typography/typography"

type TwoFactorAuthenticationFormProps = {
  setSection: (section: Sections) => void
  challengeResponseDetails: ChallengeResponseDetails
}

export default function TwoFactorAuthenticationForm({
  setSection,
  challengeResponseDetails,
}: TwoFactorAuthenticationFormProps) {
  const router = useRouter()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>()

  const searchParams = useSearchParams()
  const callbackUrl = searchParams?.get("callbackUrl")

  const { control, handleSubmit } = useForm({ defaultValues: { code: "" } })

  const handle = handleSubmit(async data => {
    setIsLoading(true)

    const response = await signIn("cognito-sign-in-with-MFA", {
      email: challengeResponseDetails.email,
      mfaCode: data.code,
      session: challengeResponseDetails.session,
      redirect: false,
    })

    if (!response) {
      setIsLoading(false)
      setError("An unexpected error occurred!")
      return
    }

    if (!response.ok && response.error) {
      setIsLoading(false)
      setError(JSON.parse(response.error).message)
      return
    }

    if (callbackUrl) {
      const url = decodeURIComponent(callbackUrl)
      if (url.startsWith("/") || url.startsWith(window.location.origin)) {
        return router.push(callbackUrl)
      }
    }

    const organizationPath = await getOrganizationPagePath()

    if (!organizationPath) {
      setIsLoading(false)
      setError(
        "We encountered an issue retrieving your organization information. Please try again.",
      )
      return
    }

    return router.push(organizationPath)
  })

  return (
    <>
      <ErrorBlock
        showError={!!error}
        text={error ?? "An unexpected error occurred!"}
        className={clsx({ "mb-4": !!error })}
      />
      {isLoading && (
        <div className="absolute w-full h-full flex justify-center items-center z-10 bg-surface-0 bg-opacity-50">
          <Loader />
        </div>
      )}

      <div className="flex flex-col gap-tokens-space-12">
        <Typography variant="h40" weight="semibold" level="h1">
          Two-Factor Authentication
        </Typography>

        <Typography variant="p14" weight="regular">
          Enter the code shown in your authenticator app
        </Typography>

        <Controller
          control={control}
          name="code"
          rules={{ required: "Code is required" }}
          render={({ field }) => <OTP {...field} onComplete={handle} autoFocus />}
        />

        <Button className="w-min" variant="secondary" onClick={() => setSection(Sections.SIGN_IN)}>
          Back
        </Button>
      </div>
    </>
  )
}
