"use client"

import { useState } from "react"

import { CreateNewPasswordForm } from "app/auth/_components"
import {
  EmailCheckForm,
  SignInForm,
  TwoFactorAuthenticationForm,
} from "app/auth/sign-in/_components"
import { Sections } from "app/auth/sign-in/_types/types"

export type ChallengeResponseDetails = {
  email: string
  session?: string
}

export default function SignInPage() {
  const [section, setSection] = useState<Sections>()
  const [challengeResponseDetails, setChallengeResponseDetails] =
    useState<ChallengeResponseDetails>()

  const getSection = (section?: Sections) => {
    switch (section) {
      case Sections.NEW_PASSWORD_REQUIRED:
        return <CreateNewPasswordForm challengeResponseDetails={challengeResponseDetails} />
      case Sections.TWO_FACTOR_AUTHENTICATION:
        if (challengeResponseDetails) {
          return (
            <TwoFactorAuthenticationForm
              setSection={setSection}
              challengeResponseDetails={challengeResponseDetails}
            />
          )
        }
        break
      case Sections.SIGN_IN:
        return (
          <SignInForm
            setChallengeResponseDetails={setChallengeResponseDetails}
            setSection={setSection}
            challengeResponseDetails={challengeResponseDetails}
          />
        )
      case Sections.EMAIL_CHECK:
      default:
        return (
          <EmailCheckForm
            setSection={setSection}
            setChallengeResponseDetails={setChallengeResponseDetails}
          />
        )
    }
  }

  return <div className="w-[23.75rem] relative">{getSection(section)}</div>
}
