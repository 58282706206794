import { JoinUsInfo } from "app/auth/sign-in/_components"
import { getSSOStatusAction } from "app/auth/sign-in/_components/EmailCheckForm/_actions/getSSOStatus.server"
import { Sections } from "app/auth/sign-in/_types/types"
import { ChallengeResponseDetails } from "app/auth/sign-in/page"
import clsx from "clsx"
import ErrorBlock from "components/Errors/ErrorBlock"
import Loader from "components/Loader/Loader"
import { signIn } from "next-auth/react"
import { useSearchParams } from "next/navigation"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { getEnableSingleSignOn } from "src/app/auth/sign-in/_components/EmailCheckForm/_actions/getEnableSingleSignOn.server"
import { Button } from "src/orca/components/Button/Button"
import { Field } from "src/orca/components/Field/field"
import { Input } from "src/orca/components/Input/input"

type EmailCheckFormProps = {
  setChallengeResponseDetails: (value: ChallengeResponseDetails) => void
  setSection: (section: Sections) => void
}

export default function EmailCheckForm({
  setChallengeResponseDetails,
  setSection,
}: EmailCheckFormProps) {
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
    },
  })

  const searchParams = useSearchParams()

  const forcedSSOSignIn = Boolean(searchParams?.get("SSO"))

  const onSubmit = handleSubmit(async ({ email }) => {
    setIsLoading(true)

    const lowerCaseEmail = email.toLowerCase()

    const emailDomain = lowerCaseEmail.split("@")[1]
    const result = await getSSOStatusAction({ emailDomain })
    const enableSingleSignOn = await getEnableSingleSignOn(lowerCaseEmail)

    if ((enableSingleSignOn && result.success && result.status === "enabled") || forcedSSOSignIn) {
      try {
        await signIn(
          "cognito-sso-sign-in",
          { callbackUrl: "/" },
          {
            // NOTE: the id_identifier is used by Cognito to route a SSO request to the correct IdP for that email domain
            idp_identifier: emailDomain,
            // NOTE: it requires `profile` scope to bring the custom attributes
            scope: "openid email profile aws.cognito.signin.user.admin",
          },
        )
        setIsLoading(false)
      } catch (error) {
        setError(typeof error === "string" ? error : "An unexpected error occurred!")
        setIsLoading(false)
      }
    } else {
      setChallengeResponseDetails({ email: lowerCaseEmail })
      setSection(Sections.SIGN_IN)
      setIsLoading(false)
    }
  })

  return (
    <>
      <ErrorBlock
        showError={!!error}
        text={error ?? "An unexpected error occurred!"}
        className={clsx({ "mb-4": !!error })}
      />
      <h1 className="mb-10 headline">{forcedSSOSignIn ? "Forced SSO sign in" : "Log in"}</h1>

      <form className="flex flex-col gap-y-10 relative" onSubmit={onSubmit}>
        {isLoading && (
          <div className="absolute w-full h-full flex justify-center items-center z-10 bg-surface-0 bg-opacity-50">
            <Loader />
          </div>
        )}

        <Controller
          control={control}
          name="email"
          rules={{ required: "Email is required" }}
          render={({ field, fieldState }) => (
            <Field id={field.name} label="Email" error={fieldState.error?.message} required>
              <Input
                {...field}
                type="email"
                placeholder="Your email address"
                autoComplete="username"
                invalid={Boolean(fieldState.error)}
                autoFocus
              />
            </Field>
          )}
        />
        <Button type="submit">Log in</Button>
      </form>
      <JoinUsInfo />
    </>
  )
}
